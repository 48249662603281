<template>
  <modal-basis v-model="isActive">
    <template #content>
      <div class="d-flex flex-column justify-center align-center mb-3 text-body-1">
        <h4 class="mb-3 text-h6 font-weight-bold">Вызов брошен</h4>
        <v-img
          :src="require(`@/assets/images/avatars/avatar-1.png`)"
          width="64"
          height="64"
          class="mb-1"
        >
        </v-img>
        <span class="font-weight-bold">{{ opponent.name }}</span>
        <p class="text-secondary-text">
          {{ opponent.statistic.rank }}
          <span class="font-weight-bold text-accent">{{ opponent.statistic.points }}</span>
        </p>
        <hr class="w-50 my-1" />
        <p>
          Тема: <span class="font-weight-bold">{{ theme }}</span>
        </p>
      </div>
    </template>
    <template #actions>
      <div class="actions d-flex justify-center align-center mx-n4">
        <v-btn
          :ripple="false"
          variant="text"
          width="50%"
          class="text-primary-light rounded-0"
          @click="playLater"
        >
          Позже
        </v-btn>
        <v-btn
          :ripple="false"
          variant="text"
          width="50%"
          class="text-primary-light rounded-0"
          @click="playNow"
        >
          Играть
        </v-btn>
      </div>
    </template>
  </modal-basis>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

import ModalBasis from '@/components/modals/ModalBasis.vue';

const props = defineProps(['modelValue', 'opponent', 'theme', 'playLater', 'playNow']);
const emit = defineEmits(['update:modelValue']);

const isActive = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style lang="scss" scoped>
.actions {
  position: relative;
  border-top: 1px solid rgba(60, 60, 67, 0.36);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: rgba(60, 60, 67, 0.36);
  }
}
</style>
