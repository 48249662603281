import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { errorHandler } from '@/utils/errorHandler';

export const useErrorStore = defineStore('error', () => {
  const error = reactive({
    code: null,
    message: '',
  });

  const setError = (newError) => {
    const { code, message } = errorHandler(newError);

    error.code = code;
    error.message = message;
  };

  const resetError = () => {
    error.code = null;
    error.message = '';
  };

  return { error, setError, resetError };
});
