import { createVuetify } from 'vuetify';

import { VBtn } from 'vuetify/components/VBtn';

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

const defaults = {
  VTextField: {
    variant: 'solo',
    density: 'comfortable',
    flat: true,
  },
  VBtn: {
    flat: true,
    class: ['text-body-2', 'font-weight-bold'],
  },
  VBtnPrimary: {
    flat: true,
    class: ['button', 'text-body-2', 'font-weight-bold', 'text-white', 'bg-primary'],
  },
  VBtnAccent: {
    flat: true,
    class: ['button', 'button-accent', 'text-body-2', 'font-weight-bold', 'text-white'],
  },
  VBtnNone: {
    flat: true,
    class: ['button', 'button-none', 'text-body-2', 'font-weight-bold', 'bg-none-light'],
  },
};

const theme = {
  defaultTheme: 'sumoLight',
  themes: {
    sumoLight: {
      dark: false,
      colors: {
        background: '#f1f6f9',
        primary: '#245fb2',
        'primary-light': '#007aff',
        accent: '#cc3629',
        'accent-light': '#f4655a',
        success: '#42a621',
        none: '#c6c6d3',
        'none-light': '#e6ebf2',
        error: '#e56054',
        tertiary: '#8a8b99',
        'tertiary-light': '#c7c8cF',
        'tertiary-darken': '#737680',
        'basic-text': '#0d0d06',
        'basic-text-light': '#1a1a1a',
        'secondary-text': '#393a40',
        'secondary-text-darken': '#404039',
        'headlines-text': '#000000',
      },
    },
  },
};

export default createVuetify({
  aliases: {
    VBtnPrimary: VBtn,
    VBtnAccent: VBtn,
    VBtnNone: VBtn,
  },
  defaults,
  theme,
});
