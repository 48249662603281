<template>
  <v-dialog
    v-model="isActive"
    max-width="624px"
  >
    <v-container class="modal-basis pb-0 rounded-lg">
      <slot name="content"></slot>
      <slot name="actions"></slot>
    </v-container>
  </v-dialog>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const isActive = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style lang="scss" scoped>
.modal-basis {
  background: #feecec;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
</style>
