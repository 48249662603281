import { isAxiosError } from 'axios';

const errorsMessages = {
  6: 'Пользователь не найден',
  7: 'Неверный код',
  8: 'Пользователь с таким email уже существует',
  9: 'Пользователь с таким именем уже существует',
};

export const errorHandler = (newError) => {
  let code = null;
  let message = '';

  if (isAxiosError(newError)) {
    const error = newError.response?.data?.error;

    code = error?.code || newError.response?.status;
    message = errorsMessages[code] || newError.message;

    return { code, message };
  }

  code = newError.code || -2;
  message = newError.message || 'Что-то пошло не так...';

  return { code, message };
};
