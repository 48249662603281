<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>

    <component
      v-if="isOpen && component"
      v-model="isOpen"
      :is="component"
      v-bind="modalProps"
    ></component>
  </v-app>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProfileStore } from '@/stores/profile';
import { useModalStore } from '@/stores/modal';
import { fetchFullProfile } from '@/api/profile';
import { getToken } from './utils/auth';

const route = useRoute();

const layout = computed(() => {
  return route.meta.layout || 'div';
});

const profileStore = useProfileStore();
const modalStore = useModalStore();

const { profile } = storeToRefs(profileStore);
const { isOpen, component, props: modalProps } = storeToRefs(modalStore);

onBeforeMount(async () => {
  const token = getToken();

  if (token) profile.value = await fetchFullProfile();
});
</script>

<style lang="scss">
@import '@/assets/styles/main';
</style>
