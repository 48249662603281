<template>
  <v-dialog
    v-model="isActive"
    :scrim="false"
    fullscreen
    transition="dialog-bottom-transition"
    class="bg-background"
  >
    <v-container class="bg-white">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        size="40"
        width="5"
        color="primary"
        class="loader"
      />

      <div
        v-else
        class="opponents"
      >
        <div>
          <div class="d-flex justify-space-between align-center mb-8">
            <h2 class="text-h5 font-weight-bold text-headlines-text">Соперник</h2>
            <v-icon
              icon="mdi-close"
              class="pointer"
              @click="onClose"
            />
          </div>

          <app-search v-model="search" />
        </div>

        <v-infinite-scroll
          empty-text=""
          color="primary"
          class="list overflow-y-hidden"
          @load="loadOpponents"
        >
          <div>
            <div class="list-item">
              <app-card
                v-if="isShowOpponents"
                class="py-3 pointer"
                @click="getRandomOpponent"
              >
                <template #img>
                  <v-img
                    width="48"
                    height="48"
                    src="@/assets/images/dice.svg"
                  />
                </template>
                <template #title>
                  <span class="text-h6 font-weight-bold"> Случайный игрок </span>
                </template>
                <template #subtitle>
                  <span class="text-body-2 text-secondary-text-darken"> От новичка до супер-легенды </span>
                </template>
              </app-card>
            </div>
            <div class="list-item">
              <app-card
                class="py-3 pointer"
                @click="openInviteLinkModal"
              >
                <template #img>
                  <v-img
                    width="48"
                    height="48"
                    src="@/assets/images/chain.svg"
                  />
                </template>
                <template #title>
                  <span class="text-h6 font-weight-bold"> Пригласить по ссылке </span>
                </template>
                <template #subtitle>
                  <span class="text-body-2 text-secondary-text-darken"> Любой сотрудник компании </span>
                </template>
              </app-card>
            </div>

            <template v-if="isShowOpponents">
              <template
                v-for="opponent in opponents"
                :key="opponent.id"
              >
                <div class="list-item">
                  <app-card
                    class="py-3 pointer"
                    @click="onChangeOpponent(opponent)"
                  >
                    <template #img>
                      <v-img
                        width="48"
                        height="48"
                        :src="require(`@/assets/images/avatars/avatar-${opponent.avatar}.png`)"
                      />
                    </template>
                    <template #title>
                      <span class="text-h6 font-weight-bold">{{ opponent.name }}</span>
                    </template>
                    <template #subtitle>
                      <span class="text-body-2 text-secondary-text-darken">
                        {{ opponent.statistic?.rank || 'Новичок' }} ({{ opponent.statistic?.points || 0 }})
                      </span>
                    </template>
                  </app-card>
                </div>
              </template>
            </template>
          </div>

          <div
            v-if="isShowNotFoundText"
            class="mt-13 text-body-1 text-center font-weight-bold text-tertiary"
          >
            <p>Оу, кажется тут никого.</p>
            <p>Позови кого-нибудь)</p>
          </div>
        </v-infinite-scroll>
      </div>
    </v-container>
  </v-dialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import debounce from 'lodash.debounce';
import { useGameStore } from '@/stores/game';
import { findOpponents, findRandomOpponent } from '@/api/game';
import { fetchProfileStatistic } from '@/api/profile';
import { fetchFullProfile } from '@/api/profile';
import { useLoading } from '@/composables/loading';

import { VInfiniteScroll } from 'vuetify/labs/VInfiniteScroll';
import AppCard from '@/components/AppCard.vue';
import AppSearch from '@/components/AppSearch.vue';

const gameStore = useGameStore();

const {
  isActiveOpponentSelection,
  isActiveThemeSelection,
  opponent,
  opponents,
  isExistsMoreOpponents,
  fetchOptionsOpponents,
} = storeToRefs(gameStore);

const { resetSelectOpponent } = gameStore;

const search = ref('');

const { isLoading } = useLoading();

const isActive = computed({
  get() {
    return isActiveOpponentSelection.value;
  },
  set(value) {
    isActiveOpponentSelection.value = value;
  },
});

const isShowOpponents = computed(() => {
  return !isLoading.value && opponents.value && Object.keys(opponents.value).length > 0;
});

const isShowNotFoundText = computed(() => {
  return !isLoading.value && opponents.value && Object.keys(opponents.value).length === 0;
});

watch(isActiveOpponentSelection, () => {
  if (isActiveOpponentSelection.value) {
    isExistsMoreOpponents.value = true;

    getOpponents();
    return;
  }

  resetSelectOpponent();
});

watch(
  search,
  debounce(() => {
    fetchOptionsOpponents.value.offset = 0;
    fetchOptionsOpponents.value.name = search;

    getOpponents();
  }, 400)
);

const getOpponents = async () => {
  isLoading.value = true;

  const { opponents: fetchedOpponents, existsMore } = await findOpponents(fetchOptionsOpponents.value);

  opponents.value = fetchedOpponents;
  isExistsMoreOpponents.value = existsMore;

  isLoading.value = false;
};

const getRandomOpponent = async () => {
  isLoading.value = true;

  const opponentId = await findRandomOpponent();
  if (opponentId) opponent.value = await fetchFullProfile(opponentId);

  isLoading.value = false;
};

const loadOpponents = async ({ done }) => {
  if (!isExistsMoreOpponents.value) {
    done('empty');
    return;
  }

  fetchOptionsOpponents.value.offset = fetchOptionsOpponents.value.offset + fetchOptionsOpponents.value.limit;

  const { opponents: fetchedOpponents, existsMore } = await findOpponents(fetchOptionsOpponents.value);

  opponents.value = {
    ...opponents.value,
    ...fetchedOpponents,
  };

  existsMore ? done('ok') : done('empty');

  isExistsMoreOpponents.value = existsMore;
};

const onChangeOpponent = async (newOpponent) => {
  isLoading.value = true;

  const opponentToSet = {
    ...newOpponent,
  };

  const profileStatistic = await fetchProfileStatistic(opponentToSet.id);

  if (profileStatistic) opponentToSet.statistic = profileStatistic;

  opponent.value = opponentToSet;

  isLoading.value = false;
};

const openInviteLinkModal = () => {
  isActiveThemeSelection.value = true;
};

const onClose = () => {
  isActive.value = false;
};
</script>

<style lang="scss" scoped>
@use '@/assets/styles/list.scss';

.opponents {
  display: grid;
  grid-template: 114px auto / 1fr;
  gap: 20px;
}
</style>
