<template>
  <opponent-selection-modal />
  <theme-selection-modal
    @start-game="onStartGame"
    @start-round="onStartRound"
  />
  <copy-invite-link />
</template>

<script setup>
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useGameStore } from '@/stores/game';
import { useModalStore } from '@/stores/modal';
import { startGame, setThemeRound } from '@/api/game';

import OpponentSelectionModal from '@/components/modals/OpponentSelection.vue';
import ThemeSelectionModal from '@/components/modals/ThemeSelection.vue';
import NewChallengeModal from '@/components/modals/NewChallenge.vue';
import CopyInviteLink from '@/components/modals/CopyInviteLink.vue';

const router = useRouter();

const gameStore = useGameStore();
const {
  theme,
  opponent,
  isActiveOpponentSelection,
  isActiveInviteLinkSection,
  isActiveThemeSelection,
  newGameParams,
  themeSelectionParams,
} = storeToRefs(gameStore);

const modalStore = useModalStore();
const { open, close } = modalStore;

watch(opponent, async () => {
  if (opponent.value) {
    isActiveThemeSelection.value = true;
  }
});

const onStartGame = async () => {
  const currentGame = await startGame(newGameParams.value);

  if (currentGame) {
    const modalProps = {
      opponent: opponent.value,
      theme: theme.value.name,
      playNow: () => {
        close();
        router.push({ name: 'game', params: { id: currentGame.id } });
      },
      playLater: () => {
        close();
      },
    };

    isActiveOpponentSelection.value = false;
    isActiveThemeSelection.value = false;
    isActiveInviteLinkSection.value = false;

    open(NewChallengeModal, modalProps);
  }
};

const onStartRound = async () => {
  const { theme_id: themeId } = (await setThemeRound(themeSelectionParams.value)) || {};

  if (themeId) router.push({ name: 'game', params: { id: themeSelectionParams.value.game_id } });
};
</script>

<style lang="scss" scoped></style>
