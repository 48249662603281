<template>
  <v-dialog
    v-model="isActive"
    :scrim="false"
    fullscreen
    class="bg-background"
  >
    <v-container class="themes bg-white">
      <div>
        <div class="d-flex justify-space-between align-center mb-4">
          <v-icon
            icon="mdi-arrow-left"
            class="pointer"
            @click="getBack"
          />
          <h2 class="text-h5 font-weight-bold text-headlines-text">Пригласить на игру по ссылке</h2>
          <v-icon
            icon="mdi-close"
            class="pointer"
            @click="onClose"
          />
        </div>

        <div class="w-100 d-flex align-center ga-5 mt-8">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="text-truncate d-underline text-black mr-8 link"
                :title="link"
                :href="link"
                target="_blank"
                @click="writeLinkInBuffer"
              >
                <v-progress-linear
                  v-if="isLoading"
                  indeterminate
                  size="40"
                  width="5"
                  color="primary"
                />
                {{ link }}
              </span>

              <v-btn-primary
                width="10%"
                max-width="624"
                :disabled="!link"
                @click="writeLinkInBuffer"
              >
                Скопировать
              </v-btn-primary>
            </template>
            <span>{{ link }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-container>

    <v-snackbar
      v-model="isSnackbarVisible"
      :timeout="3000"
      :color="snackbarText.type"
      top
    >
      {{ snackbarText.text }}
    </v-snackbar>
  </v-dialog>
</template>

<script setup>
import { getInviteLink } from '@/api/game';
import { useLoading } from '@/composables/loading';
import { useGameStore } from '@/stores/game';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

const gameStore = useGameStore();
const { isActiveInviteLinkSection, isActiveThemeSelection, theme } = storeToRefs(gameStore);

const { isLoading } = useLoading();

const link = ref('');
const isSnackbarVisible = ref(false);
const snackbarText = ref({ type: '', text: '' });

const isActive = computed({
  get() {
    return isActiveInviteLinkSection.value;
  },
  set(value) {
    isActiveInviteLinkSection.value = value;
  },
});

watch(isActiveInviteLinkSection, () => {
  if (isActiveInviteLinkSection.value) {
    getLink();
    return;
  }
});

const getLink = async () => {
  isLoading.value = true;

  const linkResponse = await getInviteLink({ theme_id: theme.value.id });
  link.value = linkResponse.url;

  isLoading.value = false;
};

const writeLinkInBuffer = async () => {
  if (!link.value) return;
  try {
    await navigator.clipboard.writeText(link.value);
    snackbarText.value = { type: 'success', text: 'Ссылка успешно скопирована!' };
  } catch (_e) {
    snackbarText.value = { type: 'error', text: 'Ошибка при записи в буфер' };
  }
  isSnackbarVisible.value = true;
};

const onClose = () => {
  isActiveThemeSelection.value = false;
  getBack();
};

const getBack = () => {
  isActiveInviteLinkSection.value = false;
  link.value = undefined;
};
</script>

<style lang="scss" scoped>
.link {
  width: 90%;
  cursor: pointer;
}
</style>
