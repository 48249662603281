import { ref, markRaw } from 'vue';
import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal', () => {
  const isOpen = ref(false);
  const props = ref(null);
  const component = ref(null);

  const open = (view, modalProps) => {
    isOpen.value = true;
    props.value = modalProps;
    component.value = markRaw(view);
  };

  const close = () => {
    isOpen.value = false;
    props.value = null;
    component.value = null;
  };

  return {
    isOpen,
    component,
    props,
    open,
    close,
  };
});
