<template>
  <v-main class="pb-15">
    <v-container class="container relative pt-0">
      <slot></slot>
    </v-container>
  </v-main>
  <header class="header w-100 bg-background">
    <v-container class="h-100 pa-0">
      <app-menu
        class="h-100"
        @new-game="onCreateGame"
      />
    </v-container>
  </header>
  <create-game />
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useGameStore } from '@/stores/game';

import AppMenu from '@/components/AppMenu.vue';
import CreateGame from '@/components/game/CreateGame.vue';

const gameStore = useGameStore();

const { isActiveOpponentSelection } = storeToRefs(gameStore);

const onCreateGame = () => {
  isActiveOpponentSelection.value = true;
};
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 60px);
}

.header {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 60px;
  box-shadow: 0px -2px 4px #d9ecf7;
}
</style>
