import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from '@/router';

import vuetify from '@/plugins/vuetify';

import App from '@/App.vue';

const pinia = createPinia();

createApp(App).use(router).use(pinia).use(vuetify).mount('#app');
