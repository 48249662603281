import axios from 'axios';
import { useErrorStore } from '@/stores/error';
import { getToken, removeToken } from '@/utils/auth';
import router from '@/router';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const token = getToken();
  const newConfig = { ...config };

  if (token) newConfig.headers = { ...newConfig.headers, Authorization: `Bearer ${token}` };

  return newConfig;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeToken();
      router.replace({ name: 'login' });
    }
    const { setError } = useErrorStore();
    setError(error);

    if (error.response.status === 401) {
      removeToken();
      router.replace({ name: 'login' });
    }
  }
);

export default instance;
