import { ref, reactive, computed } from 'vue';
import { defineStore } from 'pinia';

export const useProfileStore = defineStore('profile', () => {
  const profile = ref(null);

  const authData = reactive({
    username: '',
    email: '',
    password: '',
    phone: '',
    avatar: null,
  });

  const code = ref('');
  const step = ref(1);

  const loginData = computed(() => {
    const { email, password } = authData;

    return {
      email,
      password,
    };
  });

  const confirmationData = computed(() => {
    const { email } = authData;

    return {
      email,
      code: code.value,
    };
  });

  const setdAuthDataField = (key, value) => {
    authData[key] = value;
  };

  const setCode = (value) => {
    code.value = value;
  };

  const incrementStep = () => step.value++;

  const decrementStep = () => step.value--;

  return {
    profile,
    authData,
    loginData,
    confirmationData,
    code,
    step,
    setdAuthDataField,
    setCode,
    incrementStep,
    decrementStep,
  };
});
